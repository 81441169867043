import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Modal,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addEmployee } from "../../actions/userAuth";
import InputField1 from "./InputField1";
import CustomButton from "./CustomButton";
import { enqueueSnackbar } from "notistack";

const AddEmployeeModal = ({ open, handleClose, fetchUsers }) => {
  const [employeeData, setEmployeeData] = useState({
    employeeName: "",
    employeeId: "",
    email: "",
    phone: "",
  });

  const dispatch = useDispatch();
  const payload = {
    employeeName: employeeData.employeeName,
    employeeId: employeeData.employeeId,
    role: "Employee",
    email: employeeData.email,
    phoneNumber: employeeData.phone,
  };

  const handleSubmit = () => {
    dispatch(
      addEmployee(
        payload,
        () => {
          handleClose();
          fetchUsers();
          enqueueSnackbar("Employee created  successfully", {
            variant: "success",
          });
        },
        (error) => {
          // console.error("Error adding employee: ", error);
          enqueueSnackbar(error, {
            variant: "warning",
          });
        }
      )
    );
    console.log(payload);
  };

  const isFormValid = () => {
    return (
      employeeData.employeeName.trim() !== "" &&
      employeeData.employeeId.trim() !== "" &&
      isEmailValid(employeeData.email) &&
      isPhoneValid(employeeData.phone)
    );
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isPhoneValid = (phone) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phone);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography textAlign={"center"} variant="h3">
          Add New Employee
        </Typography>
        <>
          <InputField1
            label="Employee Id"
            placeholder="Enter Employee Id"
            name="employeeId"
            fullWidth
            value={employeeData.employeeId}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                employeeId: e.target.value,
              })
            }
          />
          <InputField1
            label="Employee Name"
            placeholder="Enter Employee Name"
            name="employeeName"
            fullWidth
            value={employeeData.employeeName}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                employeeName: e.target.value,
              })
            }
          />
          <InputField1
            label="Email"
            placeholder="Enter Email"
            name="email"
            fullWidth
            value={employeeData.email}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                email: e.target.value,
              })
            }
            error={
              !isEmailValid(employeeData.email) && employeeData.email !== ""
            }
            helperText={
              !isEmailValid(employeeData.email) && employeeData.email !== ""
                ? "Invalid email format"
                : ""
            }
          />
          <InputField1
            label="Phone"
            placeholder="Enter Phone"
            name="phone"
            fullWidth
            value={employeeData.phone}
            onChange={(e) =>
              setEmployeeData({
                ...employeeData,
                phone: e.target.value,
              })
            }
            error={
              !isPhoneValid(employeeData.phone) && employeeData.phone !== ""
            }
            helperText={
              !isPhoneValid(employeeData.phone) && employeeData.phone !== ""
                ? "Phone number must be 10 digits"
                : ""
            }
          />
        </>
        <Stack direction={"row"} spacing={2}>
          <CustomButton variant="contained" onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            Add Employee
          </CustomButton>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddEmployeeModal;
