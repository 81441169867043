import React, { useEffect, useRef, useState } from 'react';
import HomeLayout from '../../asset/layout/HomeLayout';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  Snackbar,
  IconButton,
} from '@mui/material';
import InputField1 from '../../components/mui/InputField1';
import CustomButton from '../../components/mui/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  updateCompanyDetails,
  uploadDocument,
  verifyGstNumber,
} from '../../actions/userAuth';
import { useDispatch, useSelector } from 'react-redux';
import CompanyInfo from '../companyInfo/CompanyInfo';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

const AddGst = () => {
  const [gst, setGst] = useState('');
  const [expanded, setExpanded] = useState(false); // State to manage form expansion
  const [Company, setCompany] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [CINNumber, setCINNumber] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [directorPanCardNumber, setDirectorPanCardNumber] =
    useState('');
  const [companyPAN, setCompanyPAN] = useState('');
  const [GSTStatus, setGSTStatus] = useState('');
  const [tradeName, setTradeName] = useState('');
  const [officeAddress, setOfficeAddress] = useState('');
  const [gstDocument, setGstDocument] = useState(null);
  const [aadharDocument, setAadharDocument] = useState(null);
  const [panCardDocument, setPanCardDocument] = useState(null);
  const [addressProofDocument, setAddressProofDocument] =
    useState(null);
  const [addressProofType, setAddressProofType] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();
  const gstInputRef = useRef(null);
  const aadharInputRef = useRef(null);
  const panCardInputRef = useRef(null);
  const addressProofInputRef = useRef(null);
  const [gstDocumentUrl, setGstDocumentUrl] = useState('');
  const [adharDocumentUrl, setAdharDocumentUrl] = useState('');
  const [panCardDocumentUrl, setPanCardDocumentUrl] =
    useState('');
  const [addressProofDocumentUrl, setAddressProofDocumentUrl] =
    useState('');
  const [fileUrl, setFileUrl] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, []);

  const isValidGST = (gstNumber) => {
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return gstRegex.test(gstNumber);
  };

  const handleValidateGST = () => {
    if (!isValidGST(gst)) {
      setSnackbarMessage('Invalid GST number format.');
      setSnackbarOpen(true);
    } else {
      console.log('Validating GST number...');
      const payload = { gstNumber: gst };

      dispatch(
        verifyGstNumber(
          payload,
          (response) => {
            console.log(
              'GST number verified successfully:',
              response.data
            );
            setCompany(
              response.data?.gst?.legal_name_of_business
            );
            setGSTStatus(response.data?.gst?.gst_in_status);
            setTradeName(
              response.data?.gst?.trade_name_of_business
            );
            setOfficeAddress(
              response.data?.gst?.principal_place_address
            );
            setCompanyPAN(response.data?.pan?.pan);
            setExpanded(true);
          },
          (error) => {
            console.error('Error verifying GST number:', error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);
          }
        )
      );
    }
  };
  useEffect(() => {
    handleValidateGST();
  }, []);

  const isValidPAN = (panNumber) => {
    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    return panRegex.test(panNumber);
  };

  const handleSubmit = (e) => {
    console.log('gst ', gst, Company, companyType, CINNumber);
    e.preventDefault();
    if (
      gst &&
      Company &&
      companyType &&
      CINNumber &&
      aadhar &&
      directorPanCardNumber &&
      companyPAN &&
      GSTStatus &&
      tradeName &&
      officeAddress &&
      gstDocument &&
      aadharDocument &&
      panCardDocument &&
      addressProofType &&
      addressProofDocument
    ) {
      dispatch(
        updateCompanyDetails(
          {
            companyName: Company,
            companyType: companyType,
            gst: gst,
            cinNumber: CINNumber,
            directorAdhaarNumber: aadhar,
            directorPanCardNumber: directorPanCardNumber,
            companyPanCardNumber: companyPAN,
            gstStatus: GSTStatus,
            tradeName: tradeName,
            officeAddress: officeAddress,
            gstDocument: gstDocumentUrl,
            adhaarDocument: adharDocumentUrl,
            panCardDocument: panCardDocumentUrl,
            addressProofType: addressProofType,
            addressProofDocument: addressProofDocumentUrl,
          },
          (response) => {
            console.log(
              'File uploaded successfully:',
              response.data
            );
            setFileUrl(response.data);
            setSnackbarMessage('File uploaded successfully.');
            setSnackbarOpen(true);
          },
          (error) => {
            console.error('Error uploading file:', error);

            enqueueSnackbar(error.message, {
              variant: 'error',
            });
            setSnackbarOpen(true);
          }
        )
      );
      setSnackbarMessage('Form submitted successfully!');
      setSnackbarOpen(true);

      resetForm();
    } else {
      setSnackbarMessage('Please fill in all required fields.');
      setSnackbarOpen(true);
    }
  };

  const resetForm = () => {
    // Reset all form fields
    setGst('');
    setCompany('');
    setCompanyType('');
    setDirectorPanCardNumber('');
    setCINNumber('');
    setAadhar('');
    setCompanyPAN('');
    setGSTStatus('');
    setTradeName('');
    setOfficeAddress('');
    setAddressProofType('');
    setGstDocument(null);
    setAadharDocument(null);
    setPanCardDocument(null);
    setAddressProofDocument(null);
    setExpanded(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFileUpload = (file, setUrlState) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(
        uploadDocument(
          formData,
          (response) => {
            console.log(
              'File uploaded successfully:',
              response.data
            );
            setUrlState(response.data); // Set the URL state for the uploaded document
            setSnackbarMessage('File uploaded successfully.');
            setSnackbarOpen(true);
          },
          (error) => {
            console.error('Error uploading file:', error);

            enqueueSnackbar(error.message, {
              variant: 'error',
            });
            setSnackbarOpen(true);
          }
        )
      );
    }
  };

  const handleChangeFile = (e, setFileState, setUrlState) => {
    const file = e.target.files[0];
    setFileState(file);
    handleFileUpload(file, setUrlState);
  };

  const user = JSON.parse(localStorage.getItem('siriPayUser'));

  console.log('user from store', user);

  return (
    <HomeLayout>
      {user?.gst == null ? (
        <Paper>
          <Box>
            {!expanded ? (
              <Typography variant='h4' py={4} px={10}>
                Add/verify
              </Typography>
            ) : (
              <Typography variant='h4' py={4} px={10}>
                Company Details
              </Typography>
            )}
            <Divider />
          </Box>
          <Box px={{ md: 10, xs: 2 }} py={5}>
            {!expanded ? (
              <Box>
                <InputField1
                  label='GST Number'
                  placeholder='Enter GST Number'
                  value={gst}
                  onChange={(e) => setGst(e.target.value)}
                  required
                />

                <Box mt={3}>
                  <CustomButton
                    variant='contained'
                    fullWidth
                    color='primary'
                    onClick={handleValidateGST}
                  >
                    Validate GST Number
                  </CustomButton>
                </Box>
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    {' '}
                    <InputField1
                      label='Company Name'
                      placeholder='Enter Company Name'
                      value={Company}
                      onChange={(e) =>
                        setCompany(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {' '}
                    <InputField1
                      label='Company Type'
                      placeholder='Enter Company Type'
                      value={companyType}
                      onChange={(e) =>
                        setCompanyType(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {' '}
                    <InputField1
                      label='CIN Number'
                      placeholder='Enter CIN Number'
                      value={CINNumber}
                      onChange={(e) =>
                        setCINNumber(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {' '}
                    <InputField1
                      label='Aadhar Number'
                      placeholder='Enter Aadhar Number'
                      value={aadhar}
                      onChange={(e) => setAadhar(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {' '}
                    <InputField1
                      label='Director PAN Card Number'
                      placeholder='Enter Director PAN Card Number'
                      value={directorPanCardNumber}
                      onChange={(e) =>
                        setDirectorPanCardNumber(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    {' '}
                    <InputField1
                      label='Company PAN'
                      placeholder='Enter Company PAN'
                      value={companyPAN}
                      onChange={(e) =>
                        setCompanyPAN(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    {' '}
                    <InputField1
                      label='GST Status'
                      placeholder='Enter GST Status'
                      value={GSTStatus}
                      onChange={(e) =>
                        setGSTStatus(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputField1
                      label='Trade Name'
                      placeholder='Enter Trade Name'
                      value={tradeName}
                      onChange={(e) =>
                        setTradeName(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {' '}
                    <InputField1
                      label='Office Address'
                      placeholder='Enter Office Address'
                      value={officeAddress}
                      onChange={(e) =>
                        setOfficeAddress(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputField1
                      label='Address Proof Type'
                      placeholder='Enter Address Proof Type'
                      onChange={(e) =>
                        setAddressProofType(e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputField1
                      label=' Upload GST Document:'
                      type='file'
                      accept='application/pdf'
                      onChange={(e) =>
                        handleChangeFile(
                          e,
                          setGstDocument,
                          setGstDocumentUrl
                        )
                      }
                      ref={gstInputRef}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Typography
                      variant='body1'
                      color='primary'
                    ></Typography>
                    <InputField1
                      label='  Upload Aadhar Document:'
                      type='file'
                      accept='application/pdf'
                      onChange={(e) =>
                        handleChangeFile(
                          e,
                          setAadharDocument,
                          setAdharDocumentUrl
                        )
                      }
                      ref={aadharInputRef}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <InputField1
                      label='  Upload PAN Card Document:'
                      type='file'
                      accept='application/pdf'
                      onChange={(e) =>
                        handleChangeFile(
                          e,
                          setPanCardDocument,
                          setPanCardDocumentUrl
                        )
                      }
                      ref={panCardInputRef}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <InputField1
                      label='  Upload Address Proof Document:'
                      type='file'
                      accept='application/pdf'
                      onChange={(e) =>
                        handleChangeFile(
                          e,
                          setAddressProofDocument,
                          setAddressProofDocumentUrl
                        )
                      }
                      ref={addressProofInputRef}
                    />
                  </Grid>

                  <Box mt={3}>
                    <CustomButton
                      variant='contained'
                      fullWidth
                      color='primary'
                      type='submit'
                    >
                      Submit
                    </CustomButton>
                  </Box>
                </Grid>
              </form>
            )}
          </Box>
        </Paper>
      ) : (
        <CompanyInfo />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      />
    </HomeLayout>
  );
};

export default AddGst;
