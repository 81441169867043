"use client ";
import React, { useEffect, useState } from "react";
import HomeLayout from "../../asset/layout/HomeLayout";
import {
  Container,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Checkbox,
  Paper,
  Grid,
  Input,
  Divider,
  Autocomplete,
} from "@mui/material";
import {
  AccountCircle,
  CloudUpload,
  GroupAdd,
  PersonAdd,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";

import { enqueueSnackbar } from "notistack";
import {
  employeeRewardsPoint,
  getAllEmployee,
  getAllUsers,
  rewardsPointSheet,
} from "../../actions/userAuth";
export default function RewardsPoint() {
  const [sendVia, setSendVia] = useState("email");
  const [recipientName, setRecipientName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [valueOfPoints, setValueOfPoints] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [sendMode, setSendMode] = useState("individual");
  const [users, setUsers] = useState([]);
  const [assignedToAddEdit, setAssignedToAddEdit] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleSendViaChange = (event) => {
    setSendVia(event.target.value);
  };

  const handleSendModeChange = (mode) => {
    setSendMode(mode);
  };
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const link = URL.createObjectURL(file);
      setFileLink(link);
    }
  };
  const handleIndividuallySubmit = () => {
    const payload = {
      action: "CREDIT",
      points: valueOfPoints,
      to: assignedToAddEdit,
    };
    dispatch(
      employeeRewardsPoint(
        payload,
        (data) => {
          enqueueSnackbar("Sent successfully", { variant: "success" });
          console.log(data);
        },
        (err) => {
          enqueueSnackbar("Error occurred while sending Points", {
            variant: "error",
          });
        }
      )
    );
  };
  const handleSubmitFile = () => {
    if (!fileLink) {
      enqueueSnackbar("No file selected", { variant: "warning" });
      return;
    }
    dispatch(
      rewardsPointSheet(
        { bulkUploadLink: fileLink },
        (data) => {
          enqueueSnackbar("Sent successfully", { variant: "success" });
          console.log(data);
        },
        (err) => {
          enqueueSnackbar("Error ", {
            variant: "error",
          });
        }
      )
    );
  };
  const getData = () => {
    setLoading(true);
    dispatch(
      getAllEmployee(
        (data) => {
          console.log("Data fetched successfully:", data.data);
          setLoading(false);
          setUsers(data.data);
          setAutoCompleteOptions(
            data.data.map((user) => user.username || user.phone)
          );
          console.log(data);
        },
        (error) => {
          console.error("Error fetching data:", error);
          enqueueSnackbar("Error occurred while fetching data", {
            variant: "error",
          });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, []);
  const handleAutocompleteChange = (event, newValue) => {
    const transformedValue = newValue
      .map((item) => {
        const user = users.find(
          (user) => user.username === item || user.phone === item
        );
        return user ? user.id : null;
      })
      .filter((id) => id !== null);

    setAssignedToAddEdit(transformedValue);

    // Update recipientEmail and recipientPhone based on the selected users
    if (transformedValue.length > 0) {
      const selectedUser = users.find(
        (user) => user.id === transformedValue[0]
      );
      if (selectedUser) {
        setRecipientEmail(selectedUser.email || "");
        setRecipientPhone(selectedUser.phone || "");
      }
    } else {
      setRecipientEmail("");
      setRecipientPhone("");
    }
  };
  console.log(assignedToAddEdit);
  return (
    <HomeLayout>
      <Typography variant="h4">Send Reward Point</Typography>
      <Paper
        sx={{
          mt: 2,
          borderRadius: 4,
          ".input": {
            border: "2px solid lightgrey",
            p: 1,
            borderRadius: 2,
          },
        }}
      >
        <Box display="flex" alignItems="center" sx={{ px: 4, py: 2 }}>
          <AccountCircle fontSize="large" />
          <Typography variant="h6" ml={1}>
            Add Recipients
          </Typography>
        </Box>
        <Divider></Divider>
        <Box sx={{ p: 4 }}>
          <Box
            display="flex"
            my={2}
            sx={{
              gap: 2,
              ".btn": {
                textTransform: "none",
                border: "none",

                borderRadius: 8,
                color: "black",
                outline: "none",
                "&:hover": {
                  outline: "none",
                },
              },
            }}
          >
            <Button
              variant="outlined"
              className="btn"
              startIcon={<GroupAdd />}
              onClick={() => handleSendModeChange("bulk")}
              sx={
                sendMode === "bulk"
                  ? {
                      bgcolor: "lightblue",
                      outline: "2px solid black",
                    }
                  : {
                      bgcolor: "#e8e1e1",
                    }
              }
            >
              Send in Bulk
            </Button>
            <Button
              variant="outlined"
              className="btn"
              startIcon={<PersonAdd />}
              onClick={() => handleSendModeChange("individual")}
              sx={
                sendMode === "individual"
                  ? {
                      bgcolor: "lightblue",
                      outline: "2px solid black",
                    }
                  : { bgcolor: "#e8e1e1" }
              }
            >
              Send Individually
            </Button>
          </Box>
          {sendMode === "individual" ? (
            <>
              <Box my={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography variant="body1">Send Via</Typography>
                  <RadioGroup
                    row
                    value={sendVia}
                    onChange={handleSendViaChange}
                  >
                    <FormControlLabel
                      value="email"
                      control={<Radio />}
                      label="Email address"
                    />
                    <FormControlLabel
                      value="sms"
                      control={<Radio />}
                      label="SMS"
                    />
                    <FormControlLabel
                      value="both"
                      control={<Radio />}
                      label="Both"
                    />
                  </RadioGroup>
                </Box>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="tags-filled"
                      options={autoCompleteOptions}
                      getOptionLabel={(option) => option.toString()}
                      multiple
                      value={assignedToAddEdit.map((id) => {
                        const user = users.find((user) => user.id === id);
                        return user ? user.username || user.phone : "";
                      })}
                      onChange={handleAutocompleteChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Assigned To"
                          sx={{
                            "& .MuiInputBase-root": {
                              height: "3.2rem",
                              background: "#FFFFFF",
                              borderRadius: "7px",
                              paddingRight: "8px",
                              pt: 2,
                              // py: "auto",
                            },
                            "& .MuiInputBase-input": {
                              mt: "-12px",
                            },
                            "& .MuiAutocomplete-tag": {
                              mt: "-7px !important",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {sendVia !== "sms" && (
                      <Input
                        disableUnderline={true}
                        placeholder="Enter Recipient Email Address"
                        className="input"
                        fullWidth
                        margin="normal"
                        value={recipientEmail}
                        onChange={(e) => setRecipientEmail(e.target.value)}
                      />
                    )}
                    {sendVia !== "email" && (
                      <Input
                        disableUnderline={true}
                        placeholder="Enter Recipient Phone Number"
                        className="input"
                        fullWidth
                        margin="normal"
                        value={recipientPhone}
                        onChange={(e) => setRecipientPhone(e.target.value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      disableUnderline={true}
                      placeholder="Enter Value of Points"
                      className="input"
                      fullWidth
                      margin="normal"
                      value={valueOfPoints}
                      onChange={(e) => setValueOfPoints(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" mt={1}>
                      Total: INR {valueOfPoints}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Typography variant="h6" mt={2}>
                1 Reward Point = INR 1.00
              </Typography>

              <Box
                my={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  my: 2,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: 3,
                    // bgcolor: "lightblue",
                  }}
                  onClick={handleIndividuallySubmit}
                >
                  <Typography variant="body1">Add Recipient</Typography>
                </Button>
              </Box>
            </>
          ) : (
            <Box my={2}>
              <Box
                sx={{
                  p: 2,
                  textAlign: "center",
                  border: "1px solid lightgrey",
                  mt: 2,
                  borderRadius: 4,
                }}
              >
                <CloudUpload sx={{ fontSize: 50, color: "gray" }} />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Click to upload a file
                </Typography>
                <Typography variant="body2" sx={{ color: "gray", mb: 2 }}>
                  Support CSV and XLSX files
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleSubmitFile}
                >
                  Upload File
                  <input type="file" hidden onChange={handleFileChange} />{" "}
                </Button>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  View{" "}
                  <a href="#" style={{ textDecoration: "none" }}>
                    Instructions
                  </a>{" "}
                  or download{" "}
                  <a href="#" style={{ textDecoration: "none" }}>
                    CSV template
                  </a>{" "}
                  to ensure the columns are correct.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </HomeLayout>
  );
}
