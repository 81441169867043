"use clien";
import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Avatar } from "@mui/material";
import {
  AttachMoney,
  FilterAlt,
  FileUpload,
  CurrencyRupee,
} from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getWalletTransactions, walletDataById } from "../../actions/userAuth";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const WalletModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [walletDataState, setWalletDataState] = useState([]);
  const user = JSON.parse(localStorage.getItem("siriPayUser"));
  const companyId = localStorage.getItem("siriPayUserID");
  const walletBalance = user?.balance;

  console.log(user);

  const getWAlletData = () => {
    setLoading(true);
    dispatch(
      walletDataById(
        companyId,
        (data) => {
          console.log("data is", data.data.transaction);
          setWalletDataState(data.data.transaction);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
    );
  };

  useEffect(() => {
    getWAlletData();
  }, [dispatch]);
  const [walletData, setWalletData] = useState({
    name: "Corporate Wallet",
    totalAmount: walletBalance,
  });

  const styles = {
    table: {
      minWidth: 650,
    },
    headerCell: {
      fontWeight: "bold",
    },
    cell: {
      fontWeight: 500,
    },
  };
  const handleClose = () => {
    onClose();
  };

  const handleNameChange = (event) => {
    setWalletData({ ...walletData, name: event.target.value });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { md: "70%", xs: "80%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Wallet Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CurrencyRupee sx={{ color: "#204594" }} />
            <Typography variant="h6">
              Total Amount: {walletData?.totalAmount}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            Transaction History
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FilterAlt sx={{ color: "#204594" }} />
            <FileUpload sx={{ color: "#204594" }} />
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            my: 3,
            height: 300,
            "&::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <Table style={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell style={styles.headerCell}>Request Id</TableCell>
                <TableCell style={styles.headerCell}>Name</TableCell>

                <TableCell style={styles.headerCell}>Amount</TableCell>
                <TableCell style={styles.headerCell}>Type</TableCell>

                <TableCell style={styles.headerCell}>Date</TableCell>
                <TableCell style={styles.headerCell}>Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletDataState?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={styles.cell}>{row?._id}</TableCell>
                  <TableCell style={styles.cell}>
                    {row?.businessAccountId?.companyName}
                  </TableCell>

                  <TableCell style={styles.cell}>{row?.amount}</TableCell>
                  <TableCell style={styles.cell}>{row?.type}</TableCell>

                  <TableCell style={styles.cell}>
                    {row?.date ? dayjs(row?.date).format("MM-DD-YYYY ") : ""}
                  </TableCell>
                  <TableCell style={styles.cell}>{row?.remark}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default WalletModal;
