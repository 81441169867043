import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  FormControlLabel,
  Modal,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import HomeLayout from "../../asset/layout/HomeLayout";
import IOSSwitch from "../../style/globalStyles";
import {
  addEmployeesByExcel,
  getAllEmployee,
  getAllUsers,
} from "../../actions/userAuth";
import AddEmployeeModal from "../../components/mui/AddEmployeeModal";
import CustomButton from "../../components/mui/CustomButton";
import InputField1 from "../../components/mui/InputField1";

const columns = [
  "Image",
  "Employee Id",
  "Employee Name",
  "Email",
  "Phone Number",
  "Gender",
  "Last Login",
  "Active",
];

const CompanyEmployee = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    dispatch(
      addEmployeesByExcel(
        formData,
        (response) => {
          console.log("Employees data imported successfully:", response);
          enqueueSnackbar("Employees imported successfully", {
            variant: "success",
          });
          setModalOpen(false);
          getData();
        },
        (error) => {
          console.error("Error uploading file:", error);
          setLoading(false);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      )
    );
  };

  const getData = () => {
    setLoading(true);
    dispatch(
      getAllEmployee(
        (data) => {
          console.log("Data fetched successfully:", data.data);
          setLoading(false);
          setEmployees(data.data);
        },
        (error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDateIndian = (dateString) => {
    return dayjs(dateString).format("DD/MM/YYYY");
  };

  const totalPages = Math.ceil(employees.length / countPerPage);

  const paginatedData =
    employees.length > 0
      ? employees?.slice(
          (currentPage - 1) * countPerPage,
          currentPage * countPerPage
        )
      : [];

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleViewClick = () => {
    // Replace this URL with your actual file URL
    const fileUrl =
      "https://siripay-bucket.s3.ap-south-1.amazonaws.com/users/66a09742e5ac8f6d4ac3e683/profile-pic/bdf53895-testfiel.xlsx";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "filename.xlsx"; // Specify a default filename here
    link.click();
  };
  return (
    <HomeLayout>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={3}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item md={6} xs={12}>
            {" "}
            <Typography
              variant="h4"
              my={3}
              sx={{ fontSize: { md: "2rem", xs: "1rem" } }}
            >
              Employee Management
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              mt={2}
              sx={{
                display: "flex",
                justifyContent: { md: "end", xs: "center" },
                flexDirection: "row",
              }}
            >
              <CustomButton
                variant="contained"
                width={{ md: "10rem", xs: "auto" }}
                height={"2.5rem"}
                fontSize={{ md: "1rem", xs: ".5rem" }}
                onClick={() => setAddEmployeeModalOpen(true)}
              >
                Add Employee
              </CustomButton>
              <Box ml={3}>
                {" "}
                <CustomButton
                  variant="contained"
                  width={{ md: "10rem", xs: "auto" }}
                  height={"2.5rem"}
                  onClick={handleModalOpen}
                  fontSize={{ md: "1rem", xs: ".5rem" }}
                >
                  Upload Excel File
                </CustomButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : employees?.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "25px",
            overflowX: "scroll",
            my: 3,
          }}
        >
          <Table sx={{ maxWidth: "auto" }}>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#11409B",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {paginatedData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img
                      src={row?.pictureUrl}
                      alt="UserImage"
                      style={{
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </TableCell>
                  <TableCell>{row?.employeeId}</TableCell>
                  <TableCell>{row?.username}</TableCell>
                  <TableCell>{row?.email}</TableCell>
                  <TableCell>{row?.phone}</TableCell>
                  <TableCell>{row?.gender}</TableCell>

                  <TableCell>
                    {dayjs(row.lastLogin).format("MM-DD-YYYY")}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={row.active}
                          // onChange={(e) =>
                          //   handleConfirmationOpen(row, e.target.checked)
                          // }
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
          No results found.
        </Typography>
      )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: "50%", xs: "80%" },
            bgcolor: "background.paper",
            borderRadius: "2rem",
            boxShadow: 24,
            p: 6,
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              mb={5}
            >
              Upload Excel File
            </Typography>{" "}
            <CustomButton
              onClick={handleViewClick}
              variant="contained"
              width={"10rem"}
              height={"2.5rem"}
            >
              View Sample File
            </CustomButton>
          </Stack>

          <InputField1
            fileUpload={true}
            type="file"
            id="file-upload"
            accept=".xlsx"
            py={1.2}
            onChange={handleFileChange}
          />

          <Box sx={{ mt: 2 }}>
            <CustomButton
              variant="contained"
              width={"10rem"}
              height={"2.5rem"}
              onClick={handleFileUpload}
            >
              Upload
            </CustomButton>
          </Box>
        </Box>
      </Modal>

      <AddEmployeeModal
        open={addEmployeeModalOpen}
        handleClose={() => setAddEmployeeModalOpen(false)}
        handleFileChange={handleFileChange}
        handleFileUpload={handleFileUpload}
        fetchUsers={getData}
      />
    </HomeLayout>
  );
};

export default CompanyEmployee;
